import React from 'react'
import Head from 'next/head'

interface HeadMetaProps {
	title: string
	description: string
}

export default ({ title, description }: HeadMetaProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="facebook-domain-verification" content="ho7oxp4uhbhosx08m06t8zg051k3ah" />
    </Head>
  )
}